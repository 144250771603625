const CONFIRM_STATUS_MAP = {
  CONFIRM: 1,
  FEEDBACK: 2
};
import feedbackImg from '@/assets/images/feedback.png';
import successImg from '@/assets/images/success.png';
import CommonDialog from '@/components/common/CommonDialog';
export default {
  name: 'ConfirmPayslip',
  components: {
    CommonDialog
  },
  props: {
    confirmStatus: {
      type: String,
      default: ''
    },
    feedbackData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      feedbackImg,
      successImg,
      confirmVisible: false,
      feedbackVisible: false
    };
  },
  methods: {
    // 确认工资明细
    confirmPayslipFn() {
      this.$emit('onConfirmStatus', CONFIRM_STATUS_MAP.CONFIRM);
    },
    onConfirmClose() {
      this.confirmVisible = false;
      // 更新详情数据
      this.$emit('updateData');
    },
    onFeedbackFn() {
      this.$emit('feedbackPayslip');
      this.feedbackVisible = true;
    },
    onCloseDialog() {
      this.feedbackVisible = false;
    },
    // 反馈工资明细
    onFeedbackClose() {
      this.$emit('onConfirmStatus', CONFIRM_STATUS_MAP.FEEDBACK);
    },
    // 关闭弹窗，更新详情数据
    handleFeedbackConfirmFn() {
      this.feedbackVisible = false;
      // 更新详情数据
      this.$emit('updateData');
    }
  }
};