var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip-detail"
  }, [_c("CommonHeader"), !_vm.isFirstEnter ? [_c("PersonalDetail", {
    attrs: {
      "basic-data": _vm.basicData,
      "salary-data": _vm.salaryData
    }
  }), _vm.confirmStatus ? _c("ConfirmPayslip", {
    ref: "confirmChild",
    attrs: {
      "confirm-status": _vm.confirmStatus,
      "feedback-data": _vm.feedbackData
    },
    on: {
      onConfirmStatus: _vm.handleConfirmStatus,
      feedbackPayslip: _vm.feedbackPayslipFn,
      updateData: _vm.fetchSalaryDetailFn
    }
  }) : _vm._e()] : _vm._e(), _vm.isFirstEnter ? _c("PayslipSecrecy", {
    attrs: {
      "target-path": "payslip-detail"
    },
    on: {
      toggleSecrecyVisible: _vm.onToggleSecrecyVisibleFn
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };