var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "confirm-payslip"
  }, [_c("div", {
    staticClass: "confirm-payslip-container"
  }, [_c("span", {
    staticClass: "tips"
  }, [_vm._v("温馨提示：工资属于敏感信息，请注意保密")]), _vm.confirmStatus === "0" ? [_c("el-button", {
    staticClass: "confirm-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.confirmPayslipFn
    }
  }, [_vm._v("确认 ")]), _vm._m(0), _c("el-button", {
    staticClass: "edit",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.onFeedbackFn
    }
  }, [_vm._v("我要反馈 ")])] : _vm._e(), _vm.confirmStatus === "1" ? _c("el-button", {
    staticClass: "is-disabled confirm-btn",
    attrs: {
      type: "success",
      plain: "",
      disabled: ""
    }
  }, [_vm._v("已确认")]) : _vm._e(), _vm.confirmStatus === "2" ? _c("el-button", {
    staticClass: "is-disabled confirm-btn",
    attrs: {
      type: "warning",
      plain: "",
      disabled: ""
    }
  }, [_vm._v("已反馈")]) : _vm._e()], 2), _vm.confirmVisible ? _c("CommonDialog", {
    attrs: {
      "btn-text": "我知道啦"
    },
    on: {
      click: _vm.onConfirmClose
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("section", {
    staticClass: "feedback-con"
  }, [_c("div", {
    staticClass: "image"
  }, [_c("img", {
    attrs: {
      src: _vm.successImg,
      alt: ""
    }
  })]), _c("h3", {
    staticClass: "title"
  }, [_vm._v("确认成功")])])])], 2) : _vm._e(), _vm.feedbackVisible && Object.keys(_vm.feedbackData).length > 0 ? _c("CommonDialog", {
    attrs: {
      show: true,
      "btn-text": "确认反馈"
    },
    on: {
      close: _vm.onCloseDialog,
      click: _vm.onFeedbackClose
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("section", {
    staticClass: "feedback-con"
  }, [_c("div", {
    staticClass: "image"
  }, [_c("img", {
    attrs: {
      src: _vm.feedbackImg,
      alt: ""
    }
  })]), _c("h3", {
    staticClass: "title"
  }, [_vm._v("对工资有异议？")]), _c("div", [_vm._v(" 如工资明细有问题，请联系 "), _vm._l(_vm.feedbackData, function (value, key) {
    return _c("span", {
      key: key,
      staticClass: "user-info"
    }, [_vm._v(" " + _vm._s(value) + " ")]);
  }), _vm._v(" ，重新核对工资并下发工资条 ")], 2)])])], 2) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "auto-confirm"
  }, [_vm._v(" 工资条即将在您"), _c("span", [_vm._v("收到推送后24小时")]), _vm._v("自动确认 ")]);
}];
render._withStripped = true;
export { render, staticRenderFns };