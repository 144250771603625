import CommonHeader from '@/components/common/CommonHeader';
import PersonalDetail from '@/components/payslipDetail/PersonalDetail';
import ConfirmPayslip from '@/components/payslipDetail/ConfirmPayslip';
import PayslipSecrecy from '@/components/payslipList/PayslipSecrecy';
import { formatPayslipDetail } from '@/views/payslip/util/compileData';
import { salaryDetailGetSalaryDetail, salaryDetailNormalConfirmSalaryDetail, salaryDetailErrorForConfirm } from '@/api/salary';
export default {
  name: 'PayslipDetail',
  components: {
    CommonHeader,
    PersonalDetail,
    ConfirmPayslip,
    PayslipSecrecy
  },
  data() {
    return {
      isFirstEnter: true,
      id: '',
      confirmStatus: '',
      // 确认状态
      basicData: {},
      // 基本数据
      salaryData: [],
      // 工资明细数据
      bgImg: require('@/assets/images/bg_img.png'),
      feedbackData: {} // 反馈信息数据
    };
  },
  methods: {
    onToggleSecrecyVisibleFn(isFirst) {
      this.isFirstEnter = JSON.parse(isFirst);
      if (isFirst === 'false') {
        // 不是首次 请求页面数据
        this.fetchSalaryDetailFn();
      }
    },
    // 请求工资明细数据
    async fetchSalaryDetailFn() {
      const res = await salaryDetailGetSalaryDetail(this.$route.query);
      if (res !== null && res !== void 0 && res.data) {
        const result = res.data;
        const {
          id,
          confirmStatus,
          userId,
          sendTime,
          uploadRecordId,
          sendStatus,
          ...rest
        } = result;
        this.id = id;
        this.confirmStatus = confirmStatus;
        this.handleDetailDataFn(rest);
      }
    },
    // 处理详情数据
    handleDetailDataFn(salaryData) {
      const {
        basicData,
        detailData
      } = formatPayslipDetail(salaryData);
      this.basicData = basicData;
      this.salaryData = detailData;
    },
    // 确认/反馈工资明细
    async handleConfirmStatus(confirmStatus) {
      const params = {
        id: this.id,
        confirmStatus // 确认状态，0-未确认，1-已确认，2存在问题
      };
      const res = await salaryDetailNormalConfirmSalaryDetail(params);
      if (res !== null && res !== void 0 && res.data) {
        // 修改子组件data
        const confirmStatusMap = {
          1: () => {
            this.$refs.confirmChild.confirmVisible = true;
          },
          2: () => {
            this.$refs.confirmChild.handleFeedbackConfirmFn();
          }
        };
        const strategy = confirmStatusMap[confirmStatus];
        strategy && strategy();
      }
    },
    // 获取反馈人员数据
    async feedbackPayslipFn() {
      const config = {
        // 关闭loading提示
        hideLoading: true
      };
      const res = await salaryDetailErrorForConfirm({
        id: this.id
      }, config);
      if (res !== null && res !== void 0 && res.data) {
        const {
          name,
          mobile
        } = res.data;
        const feedbackData = {
          name,
          mobile
        };
        this.feedbackData = feedbackData || {};
      }
    }
  }
};